var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"work-list"},[_c('v-toolbar',{attrs:{"flat":"","color":"gray"}},[_c('v-toolbar-title',[_vm._v("作業リスト")]),_c('v-divider',{staticClass:"mx-3",attrs:{"inset":"","vertical":""}})],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab-item',{key:item.tab},[(item.tab === '完了')?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"no-data-text":"データがありません","headers":_vm.finishedHeaders,"items":item.works},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'completed')?_c('v-chip',{attrs:{"color":"green accent-3"}},[_vm._v(" "+_vm._s(_vm.displayStatus(item.status))+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTime(item.createdAt))+" ")]}},{key:"item.finishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTime(item.finishedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'completed')?_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){return _vm.startWork(item)}}},[_vm._v("作業開始")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","to":{ name: 'WorkDetail', params: { uuid: item.uuid } }}},[_vm._v("詳細確認")])]}}],null,true)})],1):_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"no-data-text":"データがありません","headers":_vm.headers,"items":item.works},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'working')?_c('v-chip',{attrs:{"color":"red lighten-2"}},[_vm._v(" "+_vm._s(_vm.displayStatus(item.status))+" ")]):_vm._e(),(item.status === 'notStarted')?_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.displayStatus(item.status))+" ")]):_vm._e(),(item.status === 'completed')?_c('v-chip',{attrs:{"color":"green accent-3"}},[_vm._v(" "+_vm._s(_vm.displayStatus(item.status))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'completed')?_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){return _vm.startWork(item)}}},[_vm._v("作業開始")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","to":{ name: 'WorkDetail', params: { uuid: item.uuid } }}},[_vm._v("詳細確認")])]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }