

































































import { Component, Vue } from "vue-property-decorator";
import { WorksApi } from "@/services/api-service";
import { Work, WorkDetail, WorkStatus } from "@/api/api";
import AuthModule from "@/store/auth";
import WorkModule from "@/store/work";
import TokenUtil from "@/utils/TokenUtil";
import moment from "moment";

@Component({
  name: "WorkList",
})
export default class WorkList extends Vue {
  private tab = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private tabItems: any = [];

  private headers = [
    {
      text: "所有者名",
      value: "car.name",
    },
    {
      text: "車名",
      value: "car.carName",
    },
    {
      text: "車ナンバー",
      value: "car.carNumber",
    },
    {
      text: "車カラー",
      value: "car.color",
      sortable: false,
    },
    {
      text: "走行距離",
      value: "car.mileage",
      sortable: false,
    },
    {
      text: "その他",
      value: "car.description",
      sortable: false,
    },
    {
      text: "作業状態",
      value: "status",
    },
    {
      text: "アクション",
      value: "actions",
      sortable: false,
    },
  ];

  private finishedHeaders = [
    {
      text: "所有者名",
      value: "car.name",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "車名",
      value: "car.carName",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "車ナンバー",
      value: "car.carNumber",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "車カラー",
      value: "car.color",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "走行距離",
      value: "car.mileage",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "その他",
      value: "car.description",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "作業状態",
      value: "status",
      sortable: false,
      class: "fix-padding",
    },
    {
      text: "入庫日",
      value: "createdAt",
      class: "fix-padding",
    },
    {
      text: "作業完了日時",
      value: "finishedAt",
      class: "fix-padding",
    },
    {
      text: "アクション",
      value: "actions",
      sortable: false,
      class: "fix-padding",
    },
  ];

  private get me() {
    return AuthModule.me;
  }

  private logout() {
    TokenUtil.removeToken();
    AuthModule.commitMe(null);
    this.$router.push("/");
  }

  private mounted() {
    this.getWorks();
  }

  private currentTime = moment().format();

  private displayStatus(status: WorkStatus) {
    if (status === WorkStatus.NotStarted) {
      return "未着手";
    }
    if (status === WorkStatus.Working) {
      return "作業中";
    }
    if (status === WorkStatus.Completed) {
      return "完了";
    }
    return "";
  }

  private async getWorks() {
    const getWorksRes = await WorksApi.instance.getWorks();
    if (getWorksRes.data.data?.items) {
      const works = getWorksRes.data.data?.items;
      const notStartedWorks = works.filter((work: Work) => work.status === WorkStatus.NotStarted);
      const workingWorks = works.filter((work: Work) => work.status === WorkStatus.Working);
      const completedWorks = works.filter((work: Work) => work.status === WorkStatus.Completed);
      completedWorks.sort((a, b) => {
        return a > b ? 1 : -1;
      });
      this.tabItems = [
        { tab: "未着手", works: notStartedWorks },
        { tab: "作業中", works: workingWorks },
        { tab: "完了", works: completedWorks },
      ];
    }
  }

  private async deleteWork(uuid: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const deleteWork = await WorksApi.instance.deleteWorkByUuid(uuid);
    this.getWorks();
  }

  private async updateWork(value: Work) {
    try {
      const work = value;
      if (work.uuid) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const updateRes = await WorksApi.instance.updateWorkByUuid(work.uuid, work);
        this.getWorks();
      }
    } catch (err) {
      console.log(err);
    }
  }

  private getTime(time: string) {
    return moment(time).format("YYYY/MM/DD HH:mm:SS");
  }

  private async startWork(value: Work) {
    const work = value;
    if (work.startedAt) {
      if (work.uuid) {
        try {
          const res = await WorksApi.instance.getWorkbyUuid(work.uuid, false);
          const workDetail = res.data.data?.work;
          const timeSet = workDetail?.workDetails?.find((item) => item.targetTime);
          if (!workDetail?.workDetails?.length) {
            this.$router.push({ name: "SelectWorkGroup" });
          }
          if (!timeSet && workDetail?.workDetails?.length) {
            this.$router.push({ name: "WorkProcedure" });
          } else if (workDetail && timeSet) {
            WorkModule.commitWork(workDetail);
            this.$router.push({ name: "WorkProcess" });
          }
        } catch (error) {
          this.$router.push({ name: "WorkCheck" });
        }
      }
    } else {
      work.startedAt = moment().format();
      work.status = WorkStatus.Working;
      if (work.uuid) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const updateRes = await WorksApi.instance.updateWorkByUuid(work.uuid, work);
        WorkModule.commitWork(work);
      }
      this.$router.push({ name: "SelectWorkGroup" });
    }
  }

  private get work() {
    return WorkModule.work;
  }
}
