import { Vue, Component } from "vue-property-decorator";
import TokenUtil from "@/utils/TokenUtil";
import { Route } from "vue-router";
import AuthModule from "@/store/auth";
import { AuthApi } from "@/services/api-service";

Component.registerHooks(["beforeRouteEnter"]);

@Component
export default class CheckCommonLogin extends Vue {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  protected async beforeRouteEnter(to: Route, from: Route, next: any) {
    try {
      const token = TokenUtil.getToken();
      if (token === null) {
        next((vm: this) => {
          vm.$router.push({ name: "Top" });
        });
      }
      const meRes = await AuthApi.instance.getMe();
      const user = meRes.data.data?.user;

      if (user) {
        next(async () => {
          AuthModule.commitMe(user);
        });
      } else {
        TokenUtil.removeToken();
        AuthModule.commitMe(null);
        next((vm: this) => {
          vm.$router.push({ name: "Top" });
        });
      }
    } catch (err) {
      TokenUtil.removeToken();
      AuthModule.commitMe(null);
      next((vm: this) => {
        vm.$router.push({ name: "Top" });
      });
    }
  }
}
