






import { Component } from "vue-property-decorator";
import WorkList from "@/components/WorkList.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  name: "Car",
  components: {
    WorkList,
  },
})
export default class Car extends CheckCommonLogin {
  private isActive = "A";

  private change(num: string) {
    this.isActive = num;
  }
}
